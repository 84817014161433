<script setup>
const props = defineProps({
  file: {
    type: String,
    required: true
  },
  screens: {
    type: Object,
    default: {
      mob: { size: 'min' },
      tab: { size: 'min' },
      desktop: { size: 'min' },
      iwb: { size: 'org' }
    }
  },
  img: {
    type: Object
  },
  pcImage: {
    type: Boolean,
    default: false
  }
});
const { file, screens, img, pcImage } = props;

const device = useDevice();
const viewport = useViewport();

let responsiveFileName;
let imageBasePath;
const imageURL = ref();
let imageAttribs;

/**
 * takes a file name with its extension
 * returns filename and extension separately
 * @param {*} filename
 * @returns
 */
function splitFilename(filename) {
  const lastIndex = filename.lastIndexOf('.');
  const name = filename.slice(0, lastIndex);
  const ext = filename.slice(lastIndex + 1);

  return [name, ext];
}

watch(
  viewport.breakpoint,
  (newBreakpoint, oldBreakpoint) => {
    const runtimeConfig = useRuntimeConfig();
    const isDevEnv = runtimeConfig.public.appEnv == 'local';
    const nameSplit = splitFilename(file);
    if (isDevEnv) {
      imageBasePath = pcImage ? '' : '/_nuxt/';
      responsiveFileName = pcImage
        ? `/pc/img/${file}`
        : `assets/img/${nameSplit[0]}.${nameSplit[1]}`;
    } else if (nameSplit[1] == 'svg') {
      responsiveFileName = file;
    } else if (device.isMobile) {
      responsiveFileName = `${nameSplit[0]}-${screens.mob.size}.${nameSplit[1]}`;
      imageAttribs = screens.mob.img;
    } else if (device.isTablet) {
      responsiveFileName = `${nameSplit[0]}-${screens.tab.size}.${nameSplit[1]}`;
      imageAttribs = screens.tab.img;
    } else {
      if (newBreakpoint == 'desktopWide') {
        responsiveFileName = `${nameSplit[0]}.${nameSplit[1]}`;
      } else {
        responsiveFileName = `${nameSplit[0]}-${screens.desktop.size}.${nameSplit[1]}`;
      }
      imageAttribs = screens.desktop.img;
    }

    Object.assign(img, imageAttribs);

    if (!isDevEnv) {
      imageBasePath = pcImage
        ? runtimeConfig.public.cdnPCAssets + 'pc/img/'
        : runtimeConfig.public.cdnURL + '/_nuxt/';
    }

    imageURL.value = imageBasePath + responsiveFileName;
  },
  {
    immediate: true
  }
);
</script>

<template>
  <img
    v-bind="img"
    :fetchpriority="file == 'background-kings-clg.webp' ? 'high' : 'auto'"
    :src="imageURL"
    :alt="img.alt"
  />
</template>
